import React, {useMemo} from 'react';
import FormattedNumber from "@components/common/FormattedNumber";
import {format} from "date-fns";
import {ApiWalletTransactionsData, OperationType, Status} from "../../types/transactions.types";
import BigNumber from "bignumber.js";
import {getEllipsisTxt} from "@helpers/formatters";

interface TokenHistoryItemProps {
  item: ApiWalletTransactionsData;
  isLast: boolean;
  symbol: string;
  onClick: () => void;
}

const TokenHistoryItem = ({ item, isLast, symbol, onClick }: TokenHistoryItemProps) => {
  const { operation, transferred, status, date } = item;

  const isCurrentTokenIn = useMemo(() => {
    return transferred.some((item) => item.direction === 'in' && item.token === symbol);
  }, [transferred, symbol]);

  const tokenIn = useMemo(() => {
    let searchCurrent = true;
    let result = transferred.find((item) => item.direction === 'in' && item.token === symbol);
    let count = 1;

    if (!result) {
      searchCurrent = false;
      result = transferred.find((item) => item.direction === 'in' && item.token !== symbol);
    }

    if (!result) {
      return undefined;
    }

    transferred.forEach((item) => {
      if (result && item.direction === 'in' && item.value !== result.value && (searchCurrent ? item.token === symbol : item.token !== symbol)) {
        result.price = new BigNumber(item.price).plus(result.price).toNumber();
        result.quantity = new BigNumber(item.quantity).plus(result.quantity).toNumber();
        result.value = new BigNumber(item.value).plus(result.value).toNumber();
        count++;
      }
    });
    result.price = new BigNumber(result.price).dividedBy(count).toNumber();

    return result;
  }, [transferred, symbol]);

  const tokenOut = useMemo(() => {
    let searchCurrent = true;
    let result = transferred.find((item) => item.direction === 'out' && item.token === symbol);
    let count = 1;

    if (!result) {
      searchCurrent = false;
      result = transferred.find((item) => item.direction === 'out' && item.token !== symbol);
    }

    if (!result) {
      return undefined;
    }

    transferred.forEach((item) => {
      if (result && item.direction === 'out' && item.value !== result.value && (searchCurrent ? item.token === symbol : item.token !== symbol)) {
        result.price = new BigNumber(item.price).plus(result.price).toNumber();
        result.quantity = new BigNumber(item.quantity).plus(result.quantity).toNumber();
        result.value = new BigNumber(item.value).plus(result.value).toNumber();
        count++;
      }
    });
    result.price = new BigNumber(result.price).dividedBy(count).toNumber();

    return result;
  }, [transferred, symbol]);

  const symbolIn = tokenIn?.token || 'USD';
  const symbolOut = tokenOut?.token || 'USD';
  const logoIn = tokenIn?.imageUrl || '';
  const logoOut = tokenOut?.imageUrl || undefined;
  const amountIn = new BigNumber(tokenIn?.quantity || 0).toNumber();
  const amountOut = new BigNumber(tokenOut?.quantity || 0).toNumber();
  const priceIn = new BigNumber(tokenIn?.price || 0).toNumber();
  const priceOut = new BigNumber(tokenOut?.price || 0).toNumber();
  const amountInUsd = new BigNumber(tokenIn?.value || 0).toNumber();
  const amountOutUsd = new BigNumber(tokenOut?.value || 0).toNumber();

  const isBuy = operation === OperationType.Trade && symbolIn === symbol;

  const typeName = useMemo(() => {
    if (operation === OperationType.Trade) {
      return symbolIn === symbol ? 'Buy' : 'Sell';
    }
    return operation + '';
  }, [symbol, operation, symbolIn]);

  const typeClass = useMemo(() => {
    let className = 'operation ';
    if (operation === OperationType.Trade) {
      className += symbolIn === symbol ? 'operation-buy' : 'operation-sell';
    } else {
      switch (operation) {
        case OperationType.Receive:
          className += 'operation-receive';
          break;
        case OperationType.Send:
          className += 'operation-send';
          break;
        default:
          className += 'operation-default';
      }
    }
    return className;
  }, [symbol, operation, symbolIn]);

  const operationSymbol = useMemo(() => {
    let result;
    switch (operation) {
      case OperationType.Trade:
        result = !isBuy ? symbolOut : symbolIn;
        break;
      case OperationType.Send:
        result = symbolOut;
        break;
      case OperationType.Receive:
        result = symbolIn;
        break;
      default:
        result = symbol;
        break;
    }
    return result.length > 11 ? getEllipsisTxt(result, 4) : result;
  }, [operation, symbolIn, symbolOut, symbol]);

  return (
    <div className={`pb-3 mb-3 ${isLast ? '' : 'border-1 border-bottom border-muted-10'} cur-pointer with-hover`} onClick={onClick}>
      <div className={`${typeClass}`}>
        <div className="d-flex wd-100p flex-column">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="text-capitalize">{typeName.toLowerCase()}</div>
            {operation === OperationType.Trade ? (
              <FormattedNumber
                value={isBuy ? priceIn : priceOut}
                suffix=": $"
                subZeros
                decimals={6}
                className="me-auto"
              />
            ) : <div />}
            {status === Status.Pending && (
              <div className="tx-yellow">
                Pending ⌛️
              </div>
            )}
            {status === Status.Failed && (
              <div className="tx-danger">
                Failed 🚫
              </div>
            )}
            {status === Status.Confirmed && operation === OperationType.Approve && (
              <div className="tx-success">
                Confirmed
              </div>
            )}
            {status === Status.Confirmed && operation !== OperationType.Approve && (
              <FormattedNumber
                value={isBuy || operation === OperationType.Receive ? amountIn : -1 * amountOut}
                postfix={operationSymbol}
                className={`${isBuy || operation === OperationType.Receive ? 'tx-success' : 'tx-white'}`}
                withSign
              />
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            <div>
              {format(new Date(date), 'HH:mm')}
            </div>
            {operation !== OperationType.Approve && (
              <FormattedNumber
                value={amountOutUsd || amountInUsd}
                suffix="$"
                subZeros
              />
            )}
          </div>
        </div>
        {status === Status.Pending && operation !== OperationType.Receive && (
          <div className="mt-3">
            <button className="btn btn-dark bg-semi-transparent-10 border-semi-transparent-10 tx-13 px-3">
              Speed Up
            </button>
            <button className="btn btn-outline-danger border-semi-transparent ms-3 tx-13 px-3">
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenHistoryItem;
