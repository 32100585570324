import React from 'react';
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import FormattedNumber from "@components/common/FormattedNumber";
import {getEllipsisTxt} from "@helpers/formatters";
import {ApiWalletTransactionsData, OperationType, Status} from "../../../types/transactions.types";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import useTransactionDetails from "@hooks/useTransactionDetails";

interface HistoryItemProps {
  item: ApiWalletTransactionsData;
  isLast: boolean;
  onClick: () => void;
  hide?: boolean;
}

const HistoryItem = observer(({ item, isLast, onClick, hide }: HistoryItemProps) => {
  const { accountStore } = useStores();
  const { assets } = accountStore;
  const {
    address,
    chain,
    status,
    operation,
    logoIn,
    logoOut,
    symbolIn,
    symbolOut,
    amountIn,
    amountOut,
    amountInUsd,
    amountOutUsd,
    approveLogo,
    approveSymbol,
  } = useTransactionDetails(item, assets);

  return (
    <div className={`pb-3 mb-3 ${isLast ? '' : 'border-1 border-bottom border-muted-10'} cur-pointer with-hover ${hide ? 'd-none' : ''}`}>
      <div className="d-flex justify-content-between align-items-center wd-100p" onClick={onClick}>
        <HistoryLogo type={operation} symbolIn={approveSymbol || symbolIn || 'N/A'} symbolOut={symbolOut} chain={chain} mainLogo={approveLogo || logoIn} secondLogo={logoOut} />
        <div className="ms-3 wd-100p">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="text-capitalize">{operation}</div>
            {status === Status.Pending && (
              <div className="tx-yellow">
                Pending ⌛️
              </div>
            )}
            {status === Status.Failed && (
              <div className="tx-danger">
                Failed 🚫
              </div>
            )}
            {operation !== OperationType.Approve && status === Status.Confirmed && (
              <>
                {(amountIn || amountOut) ? (
                  <FormattedNumber
                    value={(amountIn || amountOut) * (operation !== OperationType.Send ? 1 : -1)}
                    postfix={`${symbolIn || symbolOut}`}
                    className={`tx-right ${operation !== OperationType.Send ? 'tx-success' : 'tx-white'}`}
                    shorten={9}
                    withSign
                  />
                ) : (<span>N/A</span>)}
              </>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            {operation !== OperationType.Trade && (
              <div>{getEllipsisTxt((address || ''), 4, '0x')}</div>
            )}
            {operation === OperationType.Trade && (
              <FormattedNumber
                suffix="-"
                value={amountOut || 0}
                postfix={`${symbolOut}`}
                shorten={9}
              />
            )}
            {operation !== OperationType.Approve && (
              <FormattedNumber
                value={amountInUsd || amountOutUsd || 0}
                suffix="$"
                shorten={9}
              />
            )}
          </div>
        </div>
      </div>
      {status === Status.Pending && operation !== OperationType.Receive && (
        <div className="mt-3">
          <button className="btn btn-dark bg-semi-transparent-10 border-semi-transparent-10 tx-13 px-3">
            Speed Up
          </button>
          <button className="btn btn-outline-danger border-semi-transparent ms-3 tx-13 px-3">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
});

export default HistoryItem;
