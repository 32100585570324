import React, {useEffect, useState} from "react";
import IdentIcon from "@components/common/IndetIcon";
import {getEllipsisTxt} from "@helpers/formatters";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import confetti from "canvas-confetti";

const WalletCreated = observer(() => {
  const navigate = useNavigate();
  const { accountStore } = useStores();
  const { address } = accountStore;
  const [isInit, setIsInit] = useState<boolean>(false);

  useEffect(() => {
    if (!isInit) {
      confetti();
      setIsInit(true);
    }
  }, [isInit]);

  return (
    <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
      <div className="rounded-30 bg-gray-800 px-2 py-1 border-1 border-solid border-semi-transparent d-flex align-items-center mb-3">
        <div className="wd-35 ht-35 rounded-circle overflow-hidden">
          <IdentIcon string={address} className="identicon rounded-circle" size={35} />
        </div>
        <div className="tx-semibold ms-2">{getEllipsisTxt(address, 4)}</div>
      </div>

      <h1 className="tx-28 mb-0">Wallet Created 🎉</h1>
      <div className="tx-muted mt-3">
        Congratulations, your wallet has been created and is ready to use!
      </div>
      <div className="mb-4">
        You can find your secret phrase in the settings
      </div>

      <button
        className="btn btn-light wd-200"
        onClick={() => navigate(PageRoutes.WALLET)}
      >
        Continue
      </button>
    </div>
  )
});

export default WalletCreated;
