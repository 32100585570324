import React from "react";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import CopyButton from "@components/common/CopyButton";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";

interface SeedPhraseProps {
  isRegister?: boolean;
}

const SeedPhrase = observer(({isRegister}: SeedPhraseProps) => {
  const { accountStore } = useStores();
  const { mnemonic } = accountStore;
  const navigate = useNavigate();
  const phrase = mnemonic.toLowerCase().split(' ');

  return (
    <div className="tx-center pb-5">
      <div className="card mt-5">
        <div className="d-flex flex-wrap">
          {phrase.map((word, index) => (
            <div className="wd-50p mb-2" key={`seed-phrase-${index}`}>
              <div className={`rounded border-1 border-solid border-muted-20 d-flex p-2 ${index % 2 === 0 ? 'me-1' : 'ms-1'}`}>
                <div className="tx-muted me-1">{index + 1}</div>
                <div>{word.replace('.', '').replace(',', '')}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="tx-13 tx-muted my-4">
        These 12 words are the key to your wallet. Back them up on the cloud or back them up manually. Do not share this with anyone
      </div>

      <CopyButton text={mnemonic} className="btn btn-secondary wd-100p mb-3" noIcon>
        Copy
      </CopyButton>
      <button
        className="btn btn-light wd-100p"
        onClick={() => navigate(isRegister ? PageRoutes.WALLET_READY : PageRoutes.WALLET)}
      >
        Continue
      </button>
    </div>
  )
});

export default SeedPhrase;
