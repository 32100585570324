import React from 'react';
import copy from "@assets/icons/copy.svg";
import ReferralShareCard from "@pages/Referral/components/ReferralShareCard";
import CopyButton from "@components/common/CopyButton";

interface ReferralShareProps {
  link: string;
  text: string;
}

const ReferralShare = ({link, text}: ReferralShareProps) => {
  return (
    <CopyButton text={link} className="wd-100p" noIcon>
      <ReferralShareCard
        title="Link"
        icon={copy}
        text={text}
      />
    </CopyButton>
  );
};

export default ReferralShare;
