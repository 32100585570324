import {Wallet} from "ethers";
import {BigNumberish} from "@ethersproject/bignumber";
import {BytesLike} from "@ethersproject/bytes";
import {AccessListish} from "@ethersproject/transactions";
import {Provider} from "@ethersproject/abstract-provider";

type TransactionRequest = {
    to?: string,
    from?: string,
    nonce?: BigNumberish,

    gasLimit?: BigNumberish,
    gasPrice?: BigNumberish,

    data?: BytesLike,
    value?: BigNumberish,
    chainId?: number

    type?: number;
    accessList?: AccessListish;

    maxPriorityFeePerGas?: BigNumberish;
    maxFeePerGas?: BigNumberish;

    customData?: Record<string, any>;
    ccipReadEnabled?: boolean;
}

const GAS_MULTIPLIERS: Record<string, number> = {
    "slow": 1,
    "normal": 1.05,
    "fast": 1.1
}

class CustomWallet extends Wallet {
    async signTransaction(transaction: TransactionRequest): Promise<string> {
        const network = await this.provider.getNetwork()
        if (network.chainId !== 56) {
            transaction.type = 0;
            transaction.gasPrice = await this.calcGasPrice()
            console.log(`transaction.gasPrice=${transaction.gasPrice.toString()}`)
            delete transaction.maxPriorityFeePerGas;
            delete transaction.maxFeePerGas;
        }

        return super.signTransaction(transaction);
    }

    connect(provider: Provider): Wallet {
        console.log(`Connecting to another provider...`)
        return new CustomWallet(this, provider);
    }

    private async calcGasPrice(): Promise<BigNumberish> {
        const gasPrice = await this.provider.getGasPrice();
        const gasMultiplier = this.getGasMultiplier();
        return gasPrice.mul(Math.round(gasMultiplier * 100)).div(100);
    }

    private getGasMultiplier() {
        try {
            const settings = JSON.parse(localStorage.getItem('li.fi-widget-settings') || '{}');
            return GAS_MULTIPLIERS[settings.state?.gasPrice || "normal"] || GAS_MULTIPLIERS["normal"];
        } catch (e) {
            return GAS_MULTIPLIERS["normal"];
        }
    }
}

export default CustomWallet