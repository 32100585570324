import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {autoTradeRoutes, PageRoutes, portfolioRoutes, profileRoutes, researchRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {ReactComponent as Wallet} from "./icons/portfolio.svg";
import {ReactComponent as Research} from "./icons/research.svg";
import {ReactComponent as TokenTrade} from "./icons/swap.svg";
import {ReactComponent as AutoTrade} from "./icons/auto-trade.svg";
import {ReactComponent as Profile} from "./icons/profile.svg";
import {isIOS} from "@helpers/device";

const BottomNav = observer(() => {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const initTop = useRef(0);
  const currentTop = useRef(0);
  const pageScrolled = useRef(false);
  const [sc, setSc] = useState(0);
  const [isInit, setIsInit] = useState(false);

  const handleClassName = (route: string | PageRoutes) => {
    let result = 'btn btn-link p-0 d-flex flex-column justify-content-center align-items-center cur-pointer wd-100p tx-10 text-decoration-none pt-1';
    if (portfolioRoutes.includes(route) && portfolioRoutes.some((r) => location.pathname.includes(r))) {
      result += ' tx-white';
    } else if (researchRoutes.includes(route) && researchRoutes.some((r) => location.pathname.includes(r))) {
      result += ' tx-white';
    } else if (autoTradeRoutes.includes(route) && autoTradeRoutes.some((r) => location.pathname.includes(r))) {
      result += ' tx-white';
    } else if (profileRoutes.includes(route) && profileRoutes.some((r) => location.pathname.includes(r))) {
      result += ' tx-white';
    } else {
      result += ' tx-muted';
    }
    return result;
  };

  useEffect(() => {
    if (ref.current && initTop.current === 0 && isInit) {
      initTop.current = ref.current.getBoundingClientRect().top;
      currentTop.current = ref.current.getBoundingClientRect().top;
    }
  }, [ref, initTop, currentTop, isInit]);

  useEffect(() => {
    if (!isInit) return;
    const onScroll = () => {
      if (ref.current) {
        setSc(window.scrollY);
        pageScrolled.current = window.scrollY >= document.body.scrollHeight - window.innerHeight - 1;
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [ref, isInit]);

  useEffect(() => {
    if (isInit) return;
    window.scrollTo({top: 0});
    setSc(0);
    setTimeout(() => {
      setIsInit(true);
    }, 500);
  }, [isInit]);

  useEffect(() => {
    setIsInit(false);
  }, [location.pathname]);

  return (
    <div id="bottom-nav" className={`${isIOS() ? 'with-notch' : ''} ${sc >= 10 && window.scrollY >= document.body.scrollHeight - window.innerHeight - 1 ? 'minimize' : ''}`} ref={ref}>
      <Link
        to={PageRoutes.WALLET}
        className={handleClassName(PageRoutes.WALLET)}
      >
        <Wallet />
        <span>Wallet</span>
      </Link>
      <Link
        to={PageRoutes.RESEARCH}
        className={handleClassName(PageRoutes.RESEARCH)}
      >
        <Research/>
        <span>Research</span>
      </Link>
      <Link
        to={PageRoutes.TOKEN_SNIPE}
        // to={'/test'}
        className="btn btn-link p-0 d-flex flex-column justify-content-center align-items-center cur-pointer wd-100p tx-10 text-decoration-none align-self-center"
      >
        <TokenTrade />
      </Link>
      <Link
        to={PageRoutes.AUTO_TRADE}
        className={handleClassName(PageRoutes.AUTO_TRADE)}
      >
        <AutoTrade />
        <span>Auto Trade</span>
      </Link>
      <Link
        to={PageRoutes.PROFILE}
        className={handleClassName(PageRoutes.PROFILE)}
      >
        <Profile />
        <span>Profile</span>
      </Link>
    </div>
  );
});

export default BottomNav;
