import IconWithLoading from "@components/common/IconWithLoading";
import check from "@assets/icons/check.svg";
import cross from "@assets/icons/cross-red.svg";
import arrowRight from "@assets/icons/arrow-right-gray.svg";
import {getEllipsisTxt} from "@helpers/formatters";
import {PositionTransactionStatus} from "../../types";

interface SnipeDetailsItemProps {
  isActive: boolean;
  status: PositionTransactionStatus;
  label: string;
  hash?: string;
  noBorder?: boolean;
}

const SnipeDetailsItem = ({isActive, status, label, hash, noBorder = false}: SnipeDetailsItemProps) => {
  return (
    <div className={`py-4 d-flex justify-content-start align-items-center ${!noBorder && 'border-1 border-bottom border-semi-transparent'} ${!isActive && 'opacity-25'}`}>
      {status === PositionTransactionStatus.PENDING && <IconWithLoading className="tx-24 me-3" isLoading />}
      {status === PositionTransactionStatus.CONFIRMED && <img src={check} alt="confirmed" width={24} height={24} className="me-3" />}
      {(status === PositionTransactionStatus.FAILED || status === PositionTransactionStatus.ERROR) && <img src={cross} alt="failed" width={24} height={24} className="me-3" />}
      <div className="d-flex flex-column justify-content-center">
        <div className="tx-17">{label}</div>
        {!!hash && (
          <a
            className="tx-muted tx-13 text-decoration-none"
            href={status === PositionTransactionStatus.CONFIRMED ? `https://etherscan.io/tx/${hash}` : '#'}
            target={status === PositionTransactionStatus.CONFIRMED ? `_blank` : undefined}
            rel="noreferrer"
          >
            Hash: {getEllipsisTxt(hash, 4)} <img src={arrowRight} alt="Hash" />
          </a>
        )}
      </div>
    </div>
  );
};

export default SnipeDetailsItem;
