import {observer} from "mobx-react-lite";
import cog from "@assets/icons/cog-white.svg";
import searchIcon from "@assets/icons/search.svg";
import gas from "@assets/icons/gas-white.svg";
import {useNavigate} from "react-router-dom";
import {ethRegex, PageRoutes} from "../../constants";
import React, {useEffect, useMemo, useState} from "react";
import useDebounce from "@hooks/useDebounce";
import {useStores} from "@hooks/useStores";
import FormattedNumber from "@components/common/FormattedNumber";
import TabsCard from "@components/common/TabsCard";
import TokenSnipeOrder from "@pages/TokenSnipe/components/TokenSnipeOrder";
import BadgeIcon from "@components/common/BadgeIcon";
import Preloader from "@components/common/Preloader";
import cross from "@assets/icons/cross.svg";
import {PositionModel, PositionStatus} from "../../types";
import useResponseHandler from "@hooks/useResponseHandler";
import BigNumber from "bignumber.js";
import useNotification from "@hooks/useNotification";
import {ApiReferral} from "@pages/Referral/constants";
import ShareProfit from "@components/ShareProfit";
import usePositionData from "@hooks/usePositionData";
import {differenceInDays} from "date-fns";
// import {useReadTextFromClipboard, useShowPopup} from "@vkruglikov/react-telegram-web-app";

const TokenSnipe = observer(() => {
  const { accountStore } = useStores();
  const { balance } = accountStore;
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [activeTab, setActiveTab] = useState('Active');
  const debounceSearch = useDebounce(search, 500);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [positions, setPositions] = useState<PositionModel[]>([]);
  const [sharePosition, setSharePosition] = useState<PositionModel | null>(null);
  const [isPositionsLoaded, setIsPositionsLoaded] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const notify = useNotification();
  const [isError, setIsError] = useState<boolean>(false);
  const [refData, setRefData] = useState<ApiReferral>();
  const {
    buyTransaction,
    sellTransaction,
    approveTransaction,
    gasCost,
    soldAmount,
    realizedProfitEth,
    positionEthValue,
    positionDiffPercent,
  } = usePositionData(sharePosition);

  useEffect(() => {
    if (!refData) {
      accountStore.getReferralData().then((data) => {
        setRefData(data);
      }).catch((response) => {
        handleResponse(response);
      });
    }
  }, [refData]);

  // const showPopup = useShowPopup();
  // const readClipboard = useReadTextFromClipboard();

  const sortedPositions = useMemo(() => {
    return positions.sort((a,b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1);
  }, [positions]);

  const activePositions = useMemo(() => {
    return sortedPositions.filter((position) => {
      return !position.hidden
          && (position.status === PositionStatus.PENDING
          || position.status === PositionStatus.IN_PROGRESS
          || position.status === PositionStatus.OPEN);
    });
  }, [sortedPositions]);

  const closedPositions = useMemo(() => {
    return sortedPositions
        .filter((position) => {
          return !position.hidden
              && (position.status === PositionStatus.COMPLETED
              || position.status === PositionStatus.ERROR
              || position.status === PositionStatus.ABORTED);
        });
  }, [sortedPositions]);

  const isAddressValid = useMemo(() => {
    return ethRegex.test(search);
  }, [search]);

  const totalGas = useMemo(() => {
    let gas = 0;
    closedPositions.forEach((position) => {
      position.transactions.forEach((tx) => {
        gas = new BigNumber(tx.meta.gasFeeInEth || 0).plus(gas).toNumber();
      });
    });
    return gas;
  }, [closedPositions]);

  const handleOrderCancel = (id: string) => {
    setIsCancelling(true);
    accountStore.cancelPosition(id).then((response) => {
      if (response) {
        notify('Order successfully cancelled');
        setIsPositionsLoaded(false);
        setIsCancelling(false);
      } else {
        handleResponse(response);
        setIsCancelling(false);
      }
    }).catch((e) => {
      console.error(e);
      handleResponse(e.response);
      setIsCancelling(false);
    });
  };

  useEffect(() => {
    if (!isPositionsLoaded) {
      accountStore.getSnipePositions()
        .then((response) => {
          console.log({response});
          if (response && !response.hasOwnProperty('data')) {
            setPositions(response);
            setIsPositionsLoaded(true);
          } else {
            handleResponse(response);
            setIsPositionsLoaded(true);
            setIsError(true);
          }
        })
        .catch((e) => {
          console.error(e);
          handleResponse(e.response);
          setIsPositionsLoaded(true);
          setIsError(true);
        });
    }
  }, [isPositionsLoaded]);

  useEffect(() => {
    if (search) {
      setIsSearching(true);
    }
    setIsInvalid(false);
  }, [search]);

  useEffect(() => {
    if (debounceSearch && isAddressValid) {
      accountStore.getTokenDetails(debounceSearch)
        .then((response) => {
          setIsSearching(false);
          if (response && response.hasOwnProperty('token')) {
            navigate('/token-snipe/' + response.token.address);
          } else {
            setIsInvalid(true);
          }
        })
        .catch(() => {
          setIsInvalid(true);
          setIsSearching(false);
        });
    }
  }, [debounceSearch, isAddressValid]);

  useEffect(() => {
    if (search && search === debounceSearch && !isAddressValid) {
      setIsSearching(false);
      setIsInvalid(true);
    }
  }, [search, debounceSearch, isAddressValid]);

  // const handleClipboard = async (clipboardContents: ClipboardItem[]) => {
  //   for (const item of clipboardContents) {
  //     for (const mimeType of item.types) {
  //       if (mimeType === "text/plain") {
  //         const blob = await item.getType("text/plain");
  //         const blobText = await blob.text();
  //         // const clipPlain = document.createElement("pre");
  //         // clipPlain.innerText = blobText;
  //         if (ethRegex.test(blobText)) {
  //           showPopup({
  //             title: 'Copy from clipboard',
  //             message: `Search for ${blobText}?`,
  //             buttons: [
  //               {
  //                 text: 'Cancel',
  //                 id: 'close',
  //               },
  //               {
  //                 text: 'Clear',
  //                 type: 'ok',
  //                 id: 'ok',
  //               },
  //             ],
  //           }).then((result) => {
  //             if (result === 'ok') {
  //               setSearch(blobText);
  //             }
  //           });
  //         }
  //       } else {
  //         console.error(`${mimeType} not supported.`);
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   // navigator.clipboard.read().then((clipboardContents) => {
  //   //   if (!clipboardContents.length) return;
  //   //   handleClipboard(clipboardContents);
  //   // });
  //   readClipboard().then((text) => {
  //     alert(`text: ${text}`);
  //     if (text && ethRegex.test(text)) {
  //       showPopup({
  //         title: 'Copy from clipboard',
  //         message: `Search for ${text}?`,
  //         buttons: [
  //           {
  //             text: 'Cancel',
  //             id: 'close',
  //           },
  //           {
  //             text: 'Clear',
  //             type: 'ok',
  //             id: 'ok',
  //           },
  //         ],
  //       }).then((result) => {
  //         if (result === 'ok') {
  //           setSearch(text);
  //         }
  //       });
  //     }
  //   }).catch((e) => {
  //     console.error(e);
  //   });
  // }, []);

  const shareData = useMemo(() => {
    if (!sharePosition) return null;
    return {
      profit: positionDiffPercent.toString(),
      duration: sharePosition.createdAt,
      token: sharePosition.token.symbol + '/WETH',
      changeUsd: '',
      changePercent: '',
      isPositive: positionDiffPercent >= 0,
      refId: refData?.refId || '',
    }
  }, [sharePosition, refData, positionDiffPercent]);

  return (
    <div className="pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between">
        <div className="tx-28 tx-semibold">
          Token Trade
        </div>
        <div onClick={() => navigate(PageRoutes.SNIPE_SETTINGS)} className="cur-pointer">
          <img src={cog} alt="settings" width={32} height={32} />
        </div>
      </div>

      <div className={`input-group my-2 rounded-2 ${isInvalid ? 'is-invalid' : ''}`}>
        <div className="input-group-text py-0 ht-35 pe-1 ps-2 border-0">
          <img src={searchIcon} alt="search" width={16} height={16} />
        </div>
        <input
          type="text"
          className={`form-control ht-35 ps-0 py-1 border-0 outline-none ${isInvalid ? 'tx-danger is-invalid' : 'tx-gray-400'}`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Token address"
        />
        {!!search && (
          <div className="input-group-text py-0 ht-35 pe-2 ps-1 border-0">
            <img src={cross} alt="Clear" width={16} height={16} onClick={() => setSearch('')} />
          </div>
        )}
        {/*{!search && (*/}
        {/*  <div className="input-group-text py-0 ht-35 pe-2 ps-1 border-0">*/}
        {/*    <img src={copy} alt="Paste" width={16} height={16} onClick={() => {*/}
        {/*      navigator.clipboard.read().then((clipboardContents) => {*/}
        {/*        if (!clipboardContents.length) return;*/}
        {/*        handleClipboard(clipboardContents);*/}
        {/*      });*/}
        {/*    }} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      <div className="d-flex align-items-center justify-content-start tx-12 tx-muted mb-4">
        <div>Balance:</div>
        <FormattedNumber
          value={balance?.balance || 0}
          decimals={4}
          subZeros={new BigNumber(balance?.balance || 0).isLessThan(0.0001)}
          floor
          postfix="ETH"
          className="tx-white ms-1"
        />
        <FormattedNumber
          value={balance?.balanceInUsd || 0}
          decimals={2}
          className="tx-white ms-1"
          suffix="($"
          postfix=")"
          noSpace
          floor
          subZeros
        />
      </div>

      {search && isSearching && (
        <div className="tx-center pt-5">
          <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block tx-semibold tx-28 mt-4" iconClass="d-block" text="Searching token..." />
        </div>
      )}

      {search && !isSearching && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">🔎</BadgeIcon>
          <h1 className="tx-28 mt-4">It's empty :(</h1>
          <div className="tx-muted my-3">Nothing was found for your request. Try specifying a query based on other data</div>
        </div>
      )}

      {isError && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">🚫</BadgeIcon>
          <h1 className="tx-28 mt-4">Error</h1>
          <div className="tx-muted mt-2 mb-3">Something went wrong</div>
        </div>
      )}

      {!isError && !search && (<>
        <TabsCard
          list={[{value: 'Active'}, {value: 'Closed'}]}
          active={activeTab}
          onClick={setActiveTab}
        >
          {!isPositionsLoaded && (
            <div className="tx-center pt-5">
              <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block tx-semibold tx-28 mt-4" iconClass="d-block" text="Loading positions..." />
            </div>
          )}
          {isPositionsLoaded && !positions.length && (
            <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
              <BadgeIcon badgeSize={85} className="tx-38 mb-2">😢</BadgeIcon>
              <div className="tx-semibold tx-22">No trades yet</div>
              <div className="tx-muted tx-17 px-4">Lets find your first gem</div>
            </div>
          )}
          {isPositionsLoaded && !!positions.length && (
            <div>
              <div className="tab-element tab-Active">
                {!activePositions.length && (
                  <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
                    <BadgeIcon badgeSize={85} className="tx-38 mb-2">😢</BadgeIcon>
                    <div className="tx-semibold tx-22">No active positions</div>
                    <div className="tx-muted tx-17 px-4">Lets find your next gem</div>
                  </div>
                )}

                {activePositions.map((position) => (
                  <TokenSnipeOrder
                    key={`position-${position.id}`}
                    data={position}
                    cancelOrder={handleOrderCancel}
                    sharePosition={() => setSharePosition(position)}
                    updatePosition={(value) => {
                      setPositions((prev) => {
                        const index = prev.findIndex((p) => p.id === value.id);
                        if (index !== -1) {
                          prev[index] = value;
                        }
                        return [...prev];
                      });
                    }}
                  />
                ))}
              </div>
              <div className="tab-element tab-Closed">
                {!closedPositions.length && (
                  <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
                    <BadgeIcon badgeSize={85} className="tx-38 mb-2">📈</BadgeIcon>
                    <div className="tx-semibold tx-22">You haven't closed any deals yet</div>
                    <div className="tx-muted tx-17 px-4">Here you can view the history of closed positions</div>
                  </div>
                )}

                {closedPositions.length > 0 && (
                  <div className="d-flex justify-content-end align-items-center tx-13 mt-2 mb-3">
                    <img src={gas} alt="gas" width={16} height={16} className="me-2" />
                    <div className="tx-muted me-1">Total:</div>
                    <FormattedNumber
                      value={totalGas}
                      decimals={4}
                      subZeros={new BigNumber(totalGas).isLessThan(0.0001)}
                      postfix="ETH"
                      className="tx-white"
                    />
                  </div>
                )}

                {closedPositions.map((position) => (
                  <TokenSnipeOrder
                    key={`position-${position.id}`}
                    data={position}
                    cancelOrder={handleOrderCancel}
                    sharePosition={() => setSharePosition(position)}
                    updatePosition={(value) => {
                      setPositions((prev) => {
                        const index = prev.findIndex((p) => p.id === value.id);
                        if (index !== -1) {
                          prev[index] = value;
                        }
                        return [...prev];
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </TabsCard>
      </>)}
      {isCancelling && <Preloader text="Cancelling position..." faster />}
      <ShareProfit data={shareData} onShare={() => setSharePosition(null)} />
    </div>
  );
});

export default TokenSnipe;
