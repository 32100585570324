import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useStores} from "@hooks/useStores";
import {ResponseData, StatusResponse} from "@helpers/api";
import useNotification from "@hooks/useNotification";

export default function useResponseHandler() {
  const {accountStore} = useStores();
  const navigate = useNavigate();
  const notify = useNotification();

  return useCallback((response?: StatusResponse<any> | ResponseData<any> | string, isError?: boolean) => {
    const toastOptions: { duration: number, id?: string } = {duration: 5000};
    let redirect = false;
    let isSessionExpired = false;
    let message: string = '';

    // console.log('useResponseHandler', {response});
    if (response && typeof response !== 'string') {
      if (response.data?.error) {
        isError = true;
        message = response?.data.error;
      }
      // console.log({messages});
      if (response.status === 403) {
        isError = true;
        message = message || 'Session expired';
        isSessionExpired = true;
        accountStore.logout();
      }
      if (!isError && response.status !== 200) {
        isError = true;
      }
    } else if (typeof response === 'string') {
      isError = isError || response.toLocaleLowerCase().indexOf('error') > -1;
      message = response;
    }

    if (isSessionExpired) {
      toastOptions.id = 'session-expired';
    }

    if (isError && !message.length) {
      message = 'An error occurred';
    } else {
      const splitted = message.split(']');
      message = splitted.length > 1 ? splitted[1] : message;
    }

    if (isError) {
      if (!redirect) {
        notify(message, {...toastOptions, type: 'danger'});
      } else {
        navigate('/error', {state: {messages: message}});
      }
    } else {
      notify(message, toastOptions);
    }
  }, [accountStore, navigate]);
}
