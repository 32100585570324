import React, {useState} from 'react';
import {chainLogo, chainName} from "@helpers/chains";
import arrow from "@assets/icons/arrow-down.svg";
import {Modal} from "react-bootstrap";
import {ChainId} from "../constants";

interface NetworkSelectionProps {
  network: ChainId;
  onChange: (network: ChainId) => void;
  allNetworks?: boolean;
  children?: React.ReactNode;
}

const NetworkSelection = ({network, onChange, allNetworks = false, children}: NetworkSelectionProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChange = (value: ChainId) => {
    setShowModal(false);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <button className="d-flex btn btn-transparent p-0 tx-left" onClick={() => setShowModal(true)}>
        {children ? children : (
          <>
            <img src={chainLogo(network)} alt={network} className="wd-40 ht-40 me-3" />

            <div>
              <div className="tx-12 tx-muted">Network</div>
              <div className="tx-17">
                {chainName(network)}
                <img src={arrow} alt="Choose network" />
              </div>
            </div>
          </>
        )}
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Networks
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.values(ChainId).map((value) => !allNetworks && value === ChainId.ALL ? null : (
            <button
              className={`btn btn-secondary ${network === value ? ' border-muted-20' : 'bg-gray-700'} d-flex align-items-center px-3 py-3 wd-100p mb-3 tx-17 tx-semibold`}
              onClick={() => handleChange(value)}
              key={`network-selection-item-${value}`}
            >
              <div className="wd-25 ht-25 d-flex align-items-center justify-content-center rounded-circle me-3 py-3">
                <img src={chainLogo(value)} alt={value} width={24} />
              </div>
              {chainName(value)}
            </button>
        ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NetworkSelection;
