import React, {useCallback} from 'react';
import TokenLogo from "@components/common/TokenLogo";
import verified from "@assets/icons/verified.svg";
import FormattedNumber from "@components/common/FormattedNumber";
import {useNavigate} from "react-router-dom";
import {IAsset} from "../../types";
import BigNumber from "bignumber.js";

interface ListItemProps {
  asset: IAsset;
  isInline?: boolean;
  inlineChange?: number;
  hide?: boolean;
}

const ListItem = ({ asset, isInline, inlineChange, hide }: ListItemProps) => {
  const navigate = useNavigate();

  const {
    assetId,
    value,
    price,
    change1d,
    change1dInPercent,
    assetName,
    quantity,
    assetSymbol,
    assetChain,
    imageUrl,
    flags,
  } = asset;

  const handleNavigate = useCallback(() => {
    if (isInline) return;

    navigate(`/asset/${assetId}`);
  }, [isInline, assetId, navigate]);

  const inlineChangeInUsd = new BigNumber(inlineChange || 0).dividedBy(100).multipliedBy(price).toNumber();

  return (
    <div
      className={`${hide ? 'd-none' : 'd-flex'} justify-content-between align-items-center wd-100p pb-3 ${isInline ? '' : 'pt-3 border-1 border-bottom border-muted-10 cur-pointer with-hover'}`}
      onClick={handleNavigate}
    >
      <TokenLogo logo={imageUrl} address={assetId} name={assetName} size={45} chain={assetChain} />

      <div className="d-flex flex-column align-items-start ms-2 me-auto">
        <div className="tx-17 mb-1">
          {assetName}
          {flags.verified && <img src={verified} alt="Verified" className="ms-1" />}
        </div>
        <FormattedNumber
          value={quantity}
          postfix={assetSymbol}
          decimals={6}
          subZeros
          className="tx-13 tx-muted word-break"
        />
      </div>

      <div className="ms-auto me-0 d-flex flex-column align-items-end">
        <div className="tx-17">
          <FormattedNumber
            suffix="$"
            value={isInline ? price : value}
            subZeros
            className="word-break"
          />
        </div>
        <div className="tx-13">
          {!isInline ? (
            <>
              <FormattedNumber
                value={change1d}
                postfix="$"
                className={`word-break ${change1d >= 0 ? 'tx-success' : 'tx-danger'}`}
                withSign={change1d !== 0}
                subZeros
              />
              <FormattedNumber
                value={change1dInPercent}
                suffix=" ("
                postfix="%)"
                className={`word-break ${change1dInPercent >= 0 ? 'tx-success' : 'tx-danger'}`}
              />
            </>
          ) : (
            <>
              <FormattedNumber
                value={inlineChangeInUsd}
                postfix="$"
                className={`word-break ${inlineChangeInUsd >= 0 ? 'tx-success' : 'tx-danger'}`}
                withSign={inlineChangeInUsd !== 0}
                subZeros
              />
              <FormattedNumber
                value={inlineChange}
                suffix=" ("
                postfix="%)"
                className={`word-break ${inlineChange && inlineChange >= 0 ? 'tx-success' : 'tx-danger'}`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
};

export default ListItem;
