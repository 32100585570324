import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {useStores} from "@hooks/useStores";
import TabsCard from "@components/common/TabsCard";
import AutoTradeStrategy from "@pages/AutoTrade/components/AutoTradeStrategy";
import BadgeIcon from "@components/common/BadgeIcon";
import Preloader from "@components/common/Preloader";
import {PositionModel, PositionTrigger} from "../../types";
import useResponseHandler from "@hooks/useResponseHandler";
import useNotification from "@hooks/useNotification";
import plus from "@assets/icons/plus.svg";
import {Strategy} from "@pages/AutoTrade/constants";
import {useShowPopup} from "@vkruglikov/react-telegram-web-app";
import TokenSnipeOrder from "@pages/TokenSnipe/components/TokenSnipeOrder";

enum AutoTradeTabs {
  STRATEGY = 'Strategy',
  HISTORY = 'History',
}

const AutoTrade = observer(() => {
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<AutoTradeTabs>(AutoTradeTabs.STRATEGY);
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [positions, setPositions] = useState<PositionModel[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPositionsLoaded, setIsPositionsLoaded] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const notify = useNotification();
  const showPopup = useShowPopup();

  const sortedPositions = useMemo(() => {
    return positions.filter((p) => p.trigger === PositionTrigger.AUTOTRADE).sort((a,b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1);
  }, [positions]);

  const sortedStrategies = useMemo(() => {
    return strategies.sort((a,b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1);
  }, [strategies]);

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    accountStore.deleteStrategy(id).then((response) => {
      if (response) {
        notify('Strategy has been deleted');
        setIsLoaded(false);
        setIsDeleting(false);
      } else {
        handleResponse(response);
        setIsDeleting(false);
      }
    }).catch((e) => {
      console.error(e);
      handleResponse(e.response);
      setIsDeleting(false);
    });
  };

  const deletePrompt = (id: string, name: string) => {
    showPopup({
      title: 'Delete Strategy',
      message: `Are you sure you want to delete the ${name}?`,
      buttons: [
        {
          text: 'Cancel',
          id: 'close',
        },
        {
          text: 'Yes',
          type: 'destructive',
          id: 'delete',
        },
      ],
    }).then((result) => {
      if (result === 'delete') {
        handleDelete(id);
      }
    });
  };

  const handleOrderCancel = (id: string) => {
    setIsCancelling(true);
    accountStore.cancelPosition(id).then((response) => {
      if (response) {
        notify('Order successfully cancelled');
        setIsPositionsLoaded(false);
        setIsCancelling(false);
      } else {
        handleResponse(response);
        setIsCancelling(false);
      }
    }).catch((e) => {
      console.error(e);
      handleResponse(e.response);
      setIsCancelling(false);
    });
  };

  useEffect(() => {
    if (!isLoaded) {
      accountStore.getStrategyList()
        .then((response) => {
          if (response) {
            setStrategies(response);
            setIsLoaded(true);
          } else {
            handleResponse(response);
          }
        })
        .catch((e) => {
          console.error(e);
          handleResponse(e.response);
          setIsLoaded(true);
        })
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!isPositionsLoaded) {
      accountStore.getSnipePositions()
        .then((response) => {
          if (response) {
            setPositions(response);
            setIsPositionsLoaded(true);
          } else {
            handleResponse(response);
          }
        })
        .catch((e) => {
          console.error(e);
          handleResponse(e.response);
          setIsPositionsLoaded(true);
        })
    }
  }, [isPositionsLoaded]);

  return (
    <div className="pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="tx-28 tx-semibold">
          Auto Trade
        </div>
        <div onClick={() => navigate(PageRoutes.AUTO_TRADE_CREATE)} className="cur-pointer">
          <img src={plus} alt="settings" width={32} height={32} />
        </div>
      </div>

      <TabsCard
        list={[{value: AutoTradeTabs.STRATEGY}, {value: AutoTradeTabs.HISTORY}]}
        active={activeTab}
        onClick={setActiveTab}
      >
        {!isLoaded && (
          <div className="tx-center pt-5">
            <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block tx-semibold tx-28 mt-4" iconClass="d-block" text="Loading positions..." />
          </div>
        )}
        {isLoaded && (
          <div>
            <div className={`tab-element tab-${AutoTradeTabs.STRATEGY}`}>
              {!strategies.length && (
                <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
                  <BadgeIcon badgeSize={85} className="tx-38 mb-2">📈</BadgeIcon>
                  <div className="tx-semibold tx-22">You haven't added a strategy yet</div>
                  <div className="tx-muted tx-17 px-4">Here you can create and edit your strategies</div>
                  <button
                    className="btn btn-light mt-3 px-3 d-flex align-items-center"
                    onClick={() => navigate(PageRoutes.AUTO_TRADE_CREATE)}
                  >
                    <img src={plus} alt="plus" width={16} height={16} className="me-2" />
                    Add my first strategy
                  </button>
                </div>
              )}
              {strategies.length > 0 && !sortedStrategies.length && (
                <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
                  <BadgeIcon badgeSize={85} className="tx-38 mb-2">😢</BadgeIcon>
                  <div className="tx-semibold tx-22">No active positions</div>
                  <div className="tx-muted tx-17 px-4">Lets find your next gem</div>1
                </div>
              )}
              {sortedStrategies.map((strategy) => (
                <AutoTradeStrategy
                  key={`strategy-${strategy.id}`}
                  data={strategy}
                  deleteStrategy={() => deletePrompt(strategy.id, strategy.name)}
                  updateStrategy={(value) => {
                    setStrategies((prev) => {
                      const index = prev.findIndex((p) => p.id === value.id);
                      if (index !== -1) {
                        prev[index] = value;
                      }
                      return [...prev];
                    });
                  }}
                />
              ))}
            </div>
            <div className={`tab-element tab-${AutoTradeTabs.HISTORY}`}>
              {positions.length > 0 && !sortedPositions.length && (
                <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
                  <BadgeIcon badgeSize={85} className="tx-38 mb-2">📈</BadgeIcon>
                  <div className="tx-semibold tx-22">You haven't closed any deals yet</div>
                  <div className="tx-muted tx-17 px-4">Here you can view the history of closed positions</div>
                </div>
              )}
              {sortedPositions.map((position) => (
                <TokenSnipeOrder
                  key={`position-${position.id}`}
                  data={position}
                  cancelOrder={handleOrderCancel}
                  updatePosition={(value) => {
                    setPositions((prev) => {
                      const index = prev.findIndex((p) => p.id === value.id);
                      if (index !== -1) {
                        prev[index] = value;
                      }
                      return [...prev];
                    });
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </TabsCard>

      {isDeleting && <Preloader text="Deleting strategy..." faster />}
      {isCancelling && <Preloader text="Cancelling position..." faster />}
    </div>
  );
});

export default AutoTrade;
