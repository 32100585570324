import FormattedNumber from "@components/common/FormattedNumber";
import React from "react";
import CopyButton from "@components/common/CopyButton";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import research from "@assets/icons/research.svg";
import eagle from "@assets/icons/eagle.svg";
import {useNavigate} from "react-router-dom";
import {HotToken} from "../../../types";
import {ChainId} from "../../../constants";
import LabelValueCard from "@components/common/LabelValueCard";
import {formatDistanceToNow} from "date-fns";

interface ResearchItemProps {
  data: HotToken;
  isGrouped?: boolean;
  isLast?: boolean;
  index: number;
}

const ResearchItem = ({data, isGrouped = false, isLast = false, index}: ResearchItemProps) => {
  const navigate = useNavigate();

  const handleTrade = () => {
    navigate('/token-snipe/' + data.pairAddress);
  };

  return (
    <div className={`${isGrouped ? (!isLast ? 'border-semi-transparent border-bottom pb-3' : '') : 'card'} overflow-hidden mb-3`}>
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p z-index-10">
        <HistoryLogo mainLogo={undefined} symbolIn={data.token.symbol} size={45} chain={ChainId.ETHER} />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="mx-wd-120 lh-2">
              <span className="tx-teal me-1">#{index + 1}</span>
              {data.token.name}
            </div>
            <div className="wd-2 ht-15 bg-semi-transparent mx-2" />
            <a href={`https://www.dextools.io/app/en/ether/pair-explorer/${data.pairAddress}`} target="_blank"
               rel="noreferrer" className="ht-16 d-flex">
              <img
                src={research}
                alt="research"
                width={16}
                height={16}
              />
            </a>
            <a href={`https://dexscreener.com/ethereum/${data.pairAddress}`} target="_blank" rel="noreferrer"
               className="ms-2 me-auto ht-16 d-flex">
              <img
                src={eagle}
                alt="dexscreener"
                width={16}
                height={16}
              />
            </a>
            <FormattedNumber
              value={data.price}
              decimals={6}
              suffix="$"
              subZeros
              className="word-break"
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <CopyButton
              text={data.token.address}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none"
              iconClass="tx-muted ms-1"
            >
            CA: {data.token.address.slice(0, 6)}
            </CopyButton>
            <CopyButton
              text={data.pairAddress}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none ms-2 me-auto"
              iconClass="tx-muted ms-1"
            >
              P: {data.pairAddress.slice(0, 6)}
            </CopyButton>
            <FormattedNumber
              value={data.priceChange24h}
              withSign
              decimals={2}
              className={data.priceChange24h > 0 ? 'tx-success' : 'tx-danger'}
              postfix="%"
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3 z-index-10">
        <LabelValueCard label="Liquidity" value={data.liquidity} unit="$"/>
        <LabelValueCard label="Volume" value={data.volume} unit="$"/>
        <LabelValueCard label="MCap" value={data.marketCap} unit="$"/>
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2 z-index-10">
        <LabelValueCard label="Age" value={formatDistanceToNow(new Date(data.createdAt)).replace('about ', '')} unit="$"/>
        <LabelValueCard label="Holders" value={data.audit.holders} unit="$"/>

        <div
          className="wd-100p card d-flex flex-column align-items-center justify-content-center bg-semi-transparent-10 p-2 tx-center tx-12 z-index-10"
        >
          <span className="tx-muted">Tax:</span>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <FormattedNumber
              value={data.audit.buyTax}
              decimals={2}
              postfix="%"
              className="ms-1 tx-success"
            />
            <span className="tx-muted mx-1">/</span>
            <FormattedNumber
              value={data.audit.sellTax}
              decimals={2}
              postfix="%"
              className="tx-danger"
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3 z-index-10">
        {/*<a*/}
        {/*  className="btn btn-secondary bg-semi-transparent-10 py-2 tx-13 wd-100p"*/}
        {/*  rel="noreferrer"*/}
        {/*  target="_blank"*/}
        {/*  href={`https://t.me/smart_ushi_bot?start=${data.pairAddress}`}*/}
        {/*>*/}
        {/*  Analytic*/}
        {/*</a>*/}
        <button
          className="btn btn-teal bg-semi-teal-10 tx-teal py-2 tx-13 wd-100p op-8"
          onClick={handleTrade}
        >
          Trade
        </button>
        {/*<button*/}
        {/*  className="btn btn-secondary py-2 tx-13 bg-semi-transparent d-flex align-items-center justify-content-center"*/}
        {/*>*/}
        {/*  <img src={heart} alt="heart" width={16} height={16} className="me-2"/>*/}
        {/*  {formatNumber(999999, undefined, 0)}*/}
        {/*</button>*/}
      </div>
    </div>
  )
};

export default ResearchItem;
