import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import SnipeRow from "@pages/Profile/SnipeRow";
import {numberRegex} from "../../constants";
import {useNavigate} from "react-router-dom";
import {useStores} from "@hooks/useStores";
import IconWithLoading from "@components/common/IconWithLoading";
import useNotification from "@hooks/useNotification";
import BigNumber from "bignumber.js";
import NumericInput from "@components/common/NumericInput";
import PageLoader from "@components/PageLoader";

const AmountSettings = observer(() => {
  const {settingsStore} = useStores();
  const {amounts, initialized, isSaving} = settingsStore;
  const navigate = useNavigate();
  const [values, setValues] = useState<string[]>([]);
  const notify = useNotification();

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log('Amounts', amounts.slice());
    if (initialized && amounts.length && !values.length) {
      setValues(amounts);
    }
  }, [initialized, amounts, values]);

  const isInvalid = useCallback((index: number) => {
    if (values[index] === '') {
      return false;
    }
    const value = new BigNumber(values[index]).toNumber();
    return isNaN(value) || !numberRegex.test(value.toString()) || value <= 0;
  }, [values]);

  const handleSaving = () => {
    const checks = [
      {check: isInvalid(0), label: 'First amount'},
      {check: isInvalid(1), label: 'Second amount'},
      {check: isInvalid(2), label: 'Third amount'},
    ];

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid', {type: 'danger'});
      return;
    }

    settingsStore.setAmounts(values);
  };

  if (!initialized || !values.length) {
    return <PageLoader text="Loading settings..." />;
  }

  return (
    <div className="pb-3" id="amount-settings">
      <div className="tx-28 tx-semibold my-3">
        Amount Settings
      </div>

      <div className="card mt-1">
        <SnipeRow>
          <div className="flex-1">
            <div className="tx-13 d-flex align-items-center mb-1">
              First
            </div>
            <div className="input-group">
              <NumericInput
                value={values[0]}
                disabled={isSaving}
                onChange={(v) => setValues([(v || '').toString(), values[1], values[2]])}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="tx-13 d-flex align-items-center mb-1">
              Second
            </div>
            <div className="input-group">
              <NumericInput
                value={values[1]}
                disabled={isSaving}
                onChange={(v) => setValues([values[0], (v || '').toString(), values[2]])}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="tx-13 d-flex align-items-center mb-1">
              Third
            </div>
            <div className="input-group">
              <NumericInput
                value={values[2]}
                disabled={isSaving}
                onChange={(v) => setValues([values[0], values[1], (v || '').toString()])}
              />
            </div>
          </div>
        </SnipeRow>
      </div>

      <div className="mt-4">
        <button
          className="btn wd-100p btn-light"
          onClick={handleSaving}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} />
          Save
        </button>

        <button
          className="btn btn-transparent tx-white wd-100p mt-2"
          onClick={handleClose}
          disabled={isSaving}
        >
          Close
        </button>
      </div>
    </div>
  );
});

export default AmountSettings;
