import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import ListItem from "@pages/Wallet/ListItem";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {IAsset, IFungible} from "../../types";
import {format} from "date-fns";
import TokenHistoryItem from "@pages/Asset/TokenHistoryItem";
import swap from "@assets/icons/swap-dark.svg";
import plane from "@assets/icons/plane-dark.svg";
import qrCode from "@assets/icons/qr-code-dark.svg";
import gas from "@assets/icons/gas-white.svg";
import {Modal} from "react-bootstrap";
import {PageRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import BigNumber from "bignumber.js";
import {ApiWalletTransactionsData, Direction, OperationType, Status} from "../../types/transactions.types";
import Placeholder from "@components/common/Placeholder";
import useNotification from "@hooks/useNotification";
import CopyButton from "@components/common/CopyButton";
import {getEllipsisTxt} from "@helpers/formatters";
import TransactionDetails from "@pages/Wallet/components/TransactionDetails";

const Asset = observer(() => {
  const { accountStore } = useStores();
  const { assets } = accountStore;
  const { address } = useParams();
  const [token, setToken] = useState<IAsset>();
  const [tokenTransactions, setTokenTransactions] = useState<ApiWalletTransactionsData[]>([]);
  const [fungible, setFungible] = useState<IFungible>();
  const [times, setTimes] = useState<string[]>([]);
  const [groups, setGroups] = useState<Array<ApiWalletTransactionsData[]>>([]);
  const [selectedTx, setSelectedTx] = useState<ApiWalletTransactionsData | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const notify = useNotification();

  const bought = useMemo(() => {
    let totalQty = 0;
    let totalUsd = 0;

    tokenTransactions.forEach((item) => {
      const { transferred } = item;
      if (item.chain === token?.assetChain && item.operation === OperationType.Trade && transferred.length > 0) {
        transferred.forEach((item) => {
          if (item.direction === Direction.In && item.token === token?.assetSymbol) {
            totalQty = new BigNumber(item.quantity).plus(totalQty).toNumber();
            totalUsd = new BigNumber(item.value).plus(totalUsd).toNumber();
          }
        });
      }
    });

    return {qty: totalQty, usd: totalUsd};
  }, [tokenTransactions, token]);

  const sold = useMemo(() => {
    let totalQty = 0;
    let totalUsd = 0;

    tokenTransactions.forEach((item) => {
      const { transferred } = item;
      if (item.chain === token?.assetChain && item.operation === OperationType.Trade && transferred.length > 0) {
        transferred.forEach((item) => {
          if (item.direction === Direction.Out && item.token === token?.assetSymbol) {
            totalQty = new BigNumber(item.quantity).plus(totalQty).toNumber();
            totalUsd = new BigNumber(item.value).plus(totalUsd).toNumber();
          }
        });
      }
    });

    return {qty: totalQty, usd: totalUsd};
  }, [tokenTransactions, token]);

  const fee = useMemo(() => {
    let totalQty = 0;
    let totalUsd = 0;
    let feeSymbol = '';

    tokenTransactions.forEach((item) => {
      const { fee, feeToken, feePrice } = item;
      if (!feeSymbol) {
        feeSymbol = feeToken;
      }
      if (feeSymbol === feeToken) {
        totalQty = new BigNumber(fee).plus(totalQty).toNumber();
        totalUsd = new BigNumber(feePrice).plus(totalUsd).toNumber();
      }
    });

    return {qty: totalQty, usd: totalUsd, symbol: feeSymbol};
  }, [tokenTransactions]);

  const {
    quantity,
    value,
    assetSymbol,
    change1d,
    change1dInPercent,
  } = token || {} as IAsset;

  useEffect(() => {
    if (!token) {
      setToken(assets.find((token) => token.assetId === address));
    }
  }, [token, assets, address]);

  useEffect(() => {
    if (token) {
      accountStore.loadFungible(token.fungibleId).then((response) => {
        setFungible(response);
      }).catch(() => {
        notify('Error while loading data', {type: 'danger'});
      });
    }
  }, [accountStore, token]);

  const sorted = useMemo(() => tokenTransactions.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  }), [tokenTransactions]);

  const queue = useMemo(() => sorted.filter((item) => item.status === Status.Pending), [sorted]);

  useEffect(() => {
    if (isLoaded) {
      const _times: string[] = [];
      const _groups: Array<ApiWalletTransactionsData[]> = [];
      sorted.forEach((item) => {
        if (item.status === Status.Pending) return;
        const time = format(new Date(item.date), 'dd.MM.yyyy');
        if (!_times.includes(time)) {
          _times.push(time);
          _groups.push([item]);
        } else {
          const index = _times.indexOf(time);
          _groups[index].push(item);
        }
      });
      setTimes(_times);
      setGroups(_groups);
    }
  }, [sorted, isLoaded]);

  useEffect(() => {
    if (fungible && token) {
      accountStore.getTransactions({fungibleId: fungible.id, chainId: token.assetChain}).then((response) => {
        setTokenTransactions(response);
        setIsLoaded(true);
      }).catch(() => {
        notify('Error while loading transactions', {type: 'danger'});
        setIsLoaded(true);
      });
    }
  }, [fungible, token, accountStore]);

  if (!token || !fungible) {
    return <Preloader className="py-3" inline />;
  }

  return (
    <div className="full-page pt-4 d-flex flex-column">
      <ListItem asset={token} inlineChange={fungible.change1d || 0} isInline />

      <div className="mt-3 mb-5 d-flex flex-column align-items-center justify-content-center tx-center">
        <FormattedNumber
          value={value}
          decimals={2}
          className="tx-34 tx-semibold"
          suffix="$"
          floor
        />
        <div className={change1d === 0 ? 'tx-muted' : change1d > 0 ? 'tx-success' : 'tx-danger'}>
          <FormattedNumber
            value={quantity}
            postfix={assetSymbol}
            className="tx-muted me-1"
          />
          <FormattedNumber
            value={change1d}
            decimals={2}
            className="tx-15 me-1"
            suffix="$"
            withSign
            floor
          />
          <FormattedNumber
            value={change1dInPercent}
            decimals={2}
            className="tx-15"
            suffix="("
            postfix="%)"
            floor
          />
        </div>
        <CopyButton text={token.contractAddress} className="btn btn-link tx-13 p-0 tx-left text-decoration-none tx-muted">
          {getEllipsisTxt(token.contractAddress, 6)}
        </CopyButton>
      </div>

      <div className="d-flex justify-content-between mb-5">
        <div className="card wd-100p me-2">
          <div className="tx-15">Bought</div>
          {isLoaded ? (
            <>
              <FormattedNumber
                value={bought.usd}
                suffix="$"
                className="tx-17"
              />
              <FormattedNumber
                value={bought.qty}
                postfix={assetSymbol}
                className="tx-muted me-1 tx-13"
              />
            </>
          ) : (
            <>
              <Placeholder width={80} height={17} />
              <Placeholder width={130} height={13} />
            </>
          )}
        </div>

        <div className="card wd-100p ms-2">
          <div className="tx-15">Sold</div>
          {isLoaded ? (
            <>
              <FormattedNumber
                value={sold.usd}
                suffix="$"
                className="tx-17"
              />
              <FormattedNumber
                value={sold.qty}
                postfix={assetSymbol}
                className="tx-muted me-1 tx-13"
              />
            </>
          ) : (
            <>
              <Placeholder width={80} height={17} />
              <Placeholder width={130} height={13} />
            </>
          )}
        </div>
      </div>

      <div className="row flex-1">
        <div className="card ht-100p" id="assets-card">
          <div className="d-flex justify-content-between mb-4">
            <div className="tx-17 tx-semibold">
              History
            </div>
            <div className="tx-13">
              <span className="tx-muted me-1">
                <img src={gas} alt="Gas" width={16} className="me-1" />
                Total:
              </span>
              <FormattedNumber
                value={fee.qty}
                decimals={6}
                postfix={fee.symbol}
              />
              <FormattedNumber
                value={fee.usd}
                decimals={2}
                subZeros
                suffix=" ($"
                postfix=")"
              />
            </div>
          </div>

          {!isLoaded && (
            <Preloader className="py-3" inline />
          )}

          {isLoaded && queue.length > 0 && (
            <div>
              <div className="tx-17 tx-semibold mb-3">
                Queue ({queue.length})
              </div>
              {queue.map((item, index) => (
                <TokenHistoryItem
                  item={item}
                  isLast={index === queue.length - 1}
                  key={`token-history-item-${item.transactionHash}-${index}`}
                  symbol={assetSymbol}
                  onClick={() => setSelectedTx(item)}
                />
              ))}
            </div>
          )}

          {isLoaded && (
            <div>
              <div className="tx-17 tx-semibold mt-3 mb-3">
                {queue.length > 0 ? 'History' : ''}
              </div>
              {times.map((time, index) => (
                <div key={`history-time-group-${time}-${index}`} className="mb-5">
                  <div className="tx-center tx-12 tx-muted my-2">
                    {time}
                  </div>
                  {groups[index].map((item, groupIndex) => (
                    <TokenHistoryItem
                      item={item}
                      isLast={groupIndex === groups[index].length - 1}
                      key={`token-history-item-${item.transactionHash}-${index}`}
                      symbol={assetSymbol}
                      onClick={() => setSelectedTx(item)}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}

          <div
            id="assets-actions"
            className="d-flex justify-content-between align-items-center gap-3"
          >
            <button
              className="btn btn-light wd-100p"
              onClick={() => navigate(PageRoutes.SWAP, {state: {asset: JSON.stringify(token)}})}
            >
              Sell
            </button>
            <button
              className="btn btn-light wd-100p"
              onClick={() => navigate(PageRoutes.SWAP, {state: {assetReceive: JSON.stringify(token)}})}
            >
              Buy
            </button>
            <button
              className="btn btn-light"
              onClick={() => setShowModal(true)}
            >
              <img src={swap} alt="swap" width={24} className="mn-wd-25" />
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            More
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-secondary bg-gray-700 d-flex align-items-center px-3 py-3 wd-100p mb-3 tx-17 tx-semibold"
            onClick={() => navigate(PageRoutes.SEND, {state: {asset: JSON.stringify(token)}})}
          >
            <div className="wd-45 ht-45 bg-white d-flex align-items-center justify-content-center rounded-circle me-3">
              <img src={plane} alt="Send" width={24} />
            </div>
            Send
          </button>

          <button
            className="btn btn-secondary bg-gray-700 d-flex align-items-center px-3 py-3 wd-100p tx-17 tx-semibold"
            onClick={() => navigate(PageRoutes.RECEIVE)}
          >
            <div className="wd-45 ht-45 bg-white d-flex align-items-center justify-content-center rounded-circle me-3">
              <img src={qrCode} alt="Receive" width={24} />
            </div>
            Receive
          </button>
        </Modal.Body>
      </Modal>

      {selectedTx && (
        <TransactionDetails item={selectedTx} onClose={() => setSelectedTx(null)} />
      )}
    </div>
  );
});

export default Asset;
