import {ISnipeSettings, PositionModel} from "../../../types";
import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import SnipeRow from "@pages/Profile/SnipeRow";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import chevron from "@assets/icons/chevron.svg";
import {numberRegex} from "../../../constants";
import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import {useParams} from "react-router-dom";
import Preloader from "@components/common/Preloader";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import useNotification from "@hooks/useNotification";
import SettingsInput from "@components/common/SettingsInput";
import {ApiError} from "@helpers/api";

interface PositionSettingsProps {
  position?: PositionModel;
  onSave?: (position: PositionModel) => void;
  onClose?: () => void;
  isSaving?: (value: boolean) => void;
}

const PositionSettings = observer((props: PositionSettingsProps) => {
  const { accountStore } = useStores();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<PositionModel>();
  const [settings, setSettings] = useState<ISnipeSettings | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const params = useParams();
  const WebApp = useWebApp();
  const notify = useNotification();

  const mainData = useMemo(() => {
    if (!position) return null;

    const {
      createdAt,
      updatedAt,
      id,
      userId,
      label,
      ...rest
    } = position.settings;

    return rest;
  }, [position]);

  const handleClose = () => {
    if (isSaving) return;
    if (!props.position && WebApp) {
      WebApp.close();
    } else {
      setSettings(JSON.parse(JSON.stringify(mainData)));
      if (props.onClose) {
        props.onClose();
      }
    }
  };

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!settings) {
      return false;
    }
    // @ts-ignore
    const value = settings[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    return isNaN(value) || !numberRegex.test(value.toString()) || !!(error && error.error?.includes(key));
  }, [settings, error]);

  const handleOnChange = useCallback((key: string, value: string | number | null) => {
    if (error && error.error?.includes(key)) {
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if (!settings && mainData) {
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [settings, mainData]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({...settings, stopLossEnabled: true});
    }
  }, [settings]);

  useEffect(() => {
    if (props.isSaving) {
      props.isSaving(isSaving);
    }
  }, [props, isSaving]);

  const handleSubmit = () => {
    if (!settings || !position) return;

    const checks = [
      {check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: 'Stop Loss'},
      {check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: 'Take Profit'},
      {check: isInvalid('stopLossSellPercent', settings.stopLossEnabled), label: 'Stop Loss Amount'},
      {check: isInvalid('takeProfitSellPercent', settings.takeProfitEnabled), label: 'Take Profit Amount'},
      {check: isInvalid('sellSlippage', true), label: 'Sell Slippage'},
      {check: isInvalid('buySlippage', true), label: 'Buy Slippage'},
      {check: isInvalid('sellGasDelta'), label: 'Sell Priority Fee'},
      {check: isInvalid('buyGasDelta'), label: 'Buy Priority Fee'},
      {check: isInvalid('approveGasDelta'), label: 'Approve Priority Fee'},
    ];

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid or empty', {type: 'danger'});
      return;
    }

    const data = JSON.parse(JSON.stringify(settings));

    if (!settings.stopLossEnabled && !settings.stopLossPercent) {
      data.stopLossPercent = data.settings.stopLossPercent || 0;
    }
    if (!settings.takeProfitEnabled && !settings.takeProfitPercent) {
      data.takeProfitPercent = data.settings.takeProfitPercent || 0;
    }
    if (settings.stopLossPercent && settings.stopLossPercent > 0) {
      data.stopLossPercent = settings.stopLossPercent * -1;
    }

    setIsSaving(true);
    accountStore.updatePosition(position.id, data).then((response) => {
      setIsSaving(false);
      if (response.hasOwnProperty('settings')) {
        notify('Position settings successfully updated');
        if (props.hasOwnProperty('onSave') && props.onSave) {
          props.onSave({...position, ...response});
        }
        handleClose();
      } else {
        notify(response.data.error, {type: 'danger'});
        setError(response.data);
      }
    }).catch((e) => {
      console.error(e);
      setIsSaving(false);
      notify('Error updating position settings', {type: 'danger'});
    });
  };

  useEffect(() => {
    if (position) return;
    if (props.position) {
      setPosition(props.position);
    } else if (params.hasOwnProperty('id') && params.id) {
      accountStore.getPosition(params.id || '')
        .then((response) => {
          if (response) {
            setPosition(response);
          }
        });
    }
  }, [position, props, params]);

  if (!settings || !position) {
    return (
      <div className="tx-center pt-5">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text="Loading position settings..." />
      </div>
    )
  }

  return (
    <>
      {!props.position && (
        <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
          <div className="tx-28 tx-semibold">
            Edit {position.token.name} settings
          </div>
        </div>
      )}
      <div>
        <div>Take Profit & Stop Loss</div>
        <div className="card mt-1 mb-2">
          <SnipeRow>
            <SnipeColumn>
              <div>Take Profit</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.takeProfitEnabled}
                onChange={(e) => setSettings({...settings, takeProfitEnabled: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>Stop Loss</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.stopLossEnabled}
                onChange={(e) => setSettings({...settings, stopLossEnabled: e.target.checked, trailingStopLossEnabled: false})}
              />
            </SnipeColumn>
          </SnipeRow>

          {settings.takeProfitEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip="The percentage of profit at which a sell will be executed"
                isRequired
              >
                Take Profit
              </SettingsInput>

              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip="The percentage of the total token amount to be sold when the specified profit level is reached"
              >
                Take Profit sell %
              </SettingsInput>
            </SnipeRow>
          )}

          {settings.stopLossEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip="The percentage of loss at which a sell will be executed"
                isRequired
              >
                Stop Loss
              </SettingsInput>

              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip="The percentage of the total token amount to be sold when the specified loss level is reached"
              >
                Stop Loss sell %
              </SettingsInput>
            </SnipeRow>
          )}
        </div>

        <div className={`mb-2 ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
          <div>General settings</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="maxGasPrice"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip="The maximum gas price you're willing to pay for a transaction"
              >
                Max Gas Price
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="approveGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
              >
                Approve Priority Fee
              </SettingsInput>
            </SnipeRow>

            <SnipeRow className="mt-3">
              <SnipeColumn>
                <div>MEV Guard</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.mevGuardEnabled}
                  onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>Fail Guard</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.failGuard}
                  onChange={(e) => setSettings({...settings, failGuard: e.target.checked})}
                />
              </SnipeColumn>
            </SnipeRow>

            {/*<SnipeRow className="mt-3">*/}
            {/*  <SnipeColumn>*/}
            {/*    <div className="tx-gray-300">Anti Rug</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      disabled*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*  <SnipeColumn>*/}
            {/*    <div>MEV Guard</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      checked={settings.mevGuardEnabled}*/}
            {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*</SnipeRow>*/}

            <SnipeRow className="mt-3">
              <SnipeColumn className="tx-12 tx-semibold">
                <div>Auto Approve</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.autoApprove}
                  onChange={(e) => setSettings({...settings, autoApprove: e.target.checked})}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>Trailing Loss</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.trailingStopLossEnabled}
                  onChange={(e) => setSettings({...settings, trailingStopLossEnabled: e.target.checked})}
                />
              </SnipeColumn>
            </SnipeRow>
          </div>

          <div className="mt-4">Buy settings</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buySlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip="The percentage of price change at which your buy will still be executed"
                isRequired
              >
                Slippage
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buyGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
              >
                Priority Fee
              </SettingsInput>
            </SnipeRow>
          </div>

          <div className="mt-4">Sell settings</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellSlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip="The percentage of price change at which your sale will still be executed"
                isRequired
                >
                Slippage
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
              >
                Priority Fee
              </SettingsInput>
            </SnipeRow>
          </div>
        </div>

        <div className="pb-4 mb-2 mt-4 tx-13 tx-center border-bottom border-semi-transparent cur-pointer with-hover" onClick={() => setIsDetailsOpen((prev) => !prev)}>
          <span>More settings</span>
          <img src={chevron} alt="toggle details" width={16} height={16} className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`} />
        </div>
      </div>

      <button
        className="btn btn-light wd-100p mt-3"
        onClick={handleSubmit}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} />
        Save
      </button>

      <button
        className="btn btn-transparent wd-100p mt-2 mb-3"
        onClick={handleClose}
        disabled={isSaving}
      >
        Cancel
      </button>
    </>
  );
});

export default PositionSettings;
