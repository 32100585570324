import React, {useEffect} from "react";
import {useAnimate} from "framer-motion";
import {randomNumber} from "@helpers/numbers";

export interface TabCardItem {
  value: string | number;
  label?: string;
}

interface TabsCardProps {
  list: TabCardItem[];
  active?: string | number;
  onClick?: (value: any) => void;
  className?: string;
  tabClassName?: string;
  children: React.ReactNode;
}

export default function TabsCard({list, active, onClick, className = '', tabClassName = 'py-2', children}: TabsCardProps) {
  const number = randomNumber();
  const [scope, animate] = useAnimate();
  const activeIndex = list.findIndex(({value}) => value === active);
  const width = 100 / list.length;

  useEffect(() => {
    animate(`#tab-indicator-${number}`, { left: `calc(${width * activeIndex}% + 4px)`, zIndex: 1 });
    list.forEach(({value}) => {
      const isActive = value === active;
      if (document.querySelector(`.tab-element.tab-${value}`)) {
        animate(`.tab-element.tab-${value}`, { opacity: isActive ? 1 : 0, display: isActive ? 'block' : 'none' }, { duration: 0.3, delay: isActive ? 0.2 : 0 });
      }
    });
  }, [list, activeIndex, animate]);

  return (
    <div className={className} ref={scope}>
      <div className="card flex-row p-1 justify-content-between bg-semi-transparent-10 mb-3">
        <div
          className="bg-semi-transparent rounded-2 position-absolute"
          style={{width: `calc(${width}% - 8px)`, height: 'calc(100% - 8px)', top: '4px'}}
          id={`tab-indicator-${number}`}
        />
        {list.map(({value, label}, index) => (
          <div
            className={`tx-14 rounded-2 py-1 tx-center cur-pointer wd-100p ${value !== active ? 'tx-muted' : ''} ${tabClassName}`}
            onClick={() => onClick && onClick(value)}
            key={`tab-card-${value}-${index}`}
            style={{zIndex: 2}}
          >
            {label || value}
          </div>
        ))}
      </div>
      <div className="tab-content position-relative">
        {children}
      </div>
    </div>
  );
}
