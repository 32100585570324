import React from "react";
import {useNavigate} from "react-router-dom";
import BadgeIcon from "@components/common/BadgeIcon";
import {PageRoutes} from "../../constants";

const GetSeedPhrase = () => {
  const navigate = useNavigate();

  return (
    <div className="tx-center">
      <BadgeIcon className="mt-5">
        <div className="tx-38 lh--1">🔒</div>
      </BadgeIcon>
      <h1 className="tx-28 mt-4">Get a seed phrase</h1>

      <div className="tx-muted my-3">Before creating a new seed phrase, read the warnings</div>

      <div className="card d-flex flex-row justify-content-start align-items-center tx-left mb-3">
        <div className="tx-24 lh--1 me-3">💾</div>
        <div>Save the seed phrase</div>
      </div>
      <div className="card d-flex flex-row justify-content-start align-items-center tx-left mb-3">
        <div className="tx-24 lh--1 me-3">🤫</div>
        <div>Don't tell anyone your seed phrase</div>
      </div>
      <div className="card d-flex flex-row justify-content-start align-items-center tx-left mb-3">
        <div className="tx-24 lh--1 me-3">📱</div>
        <div>Without seed phrase, it is impossible to access the wallet on another device</div>
      </div>

      <button
        className="btn btn-light wd-200"
        onClick={() => navigate(PageRoutes.SEED_PHRASE)}
      >
        Get Seed Phrase
      </button>
    </div>
  )
};

export default GetSeedPhrase;
