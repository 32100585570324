import React from 'react';
import lock from "@assets/icons/lock.svg";
import share from "@assets/icons/share.svg";
// import support from "@assets/icons/support.svg";
// import logo from "@assets/images/logo/logo.svg";
import info from "@assets/icons/info.svg";
import SideNavItem, {SideNavItemProps} from "@pages/Wallet/SideNavItem";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import group from "@assets/icons/group.svg";
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import IdentIcon from "@components/common/IndetIcon";
import FormattedNumber from "@components/common/FormattedNumber";
import {getEllipsisTxt} from "@helpers/formatters";

interface NavGroup {
  title?: string;
  items: SideNavItemProps[];
}

const Profile = observer(() => {
  const { accountStore } = useStores();
  const { address, portfolio } = accountStore;
  const navigate = useNavigate();

  const navGroups: NavGroup[] = [
    {
      items: [
        {
          icon: group,
          title: 'Referral Program',
          onClick: () => navigate(PageRoutes.REFERRAL),
        },
      ],
    },
    {
      title: 'Settings',
      items: [
        {
          title: 'Export seed phrase',
          icon: share,
          onClick: () => navigate(PageRoutes.EXPORT_SEED_PHRASE),
        },
        {
          title: 'Change PIN',
          icon: lock,
          onClick: () => navigate(PageRoutes.CHANGE_PIN),
        },
        {
          title: 'ETH Amount',
          icon: info,
          onClick: () => navigate(PageRoutes.AMOUNT_SETTINGS),
        },
      ],
    },
    // {
    //   title: 'Help',
    //   items: [
    //     {
    //       icon: support,
    //       title: 'Support',
    //       onClick: () => window.open('https://t.me/alpha_dex_io', '_blank'),
    //     },
    //     {
    //       icon: logo,
    //       title: 'About Us',
    //       onClick: () => window.open('https://alpha-dex.io/', '_blank'),
    //     },
    //   ],
    // },
  ];

  return (
    <div className="pt-4">
      <div className="card d-flex flex-row justify-content-start align-items-center mb-4">
        <IdentIcon string={address} className="identicon rounded-circle" size={40} />
        <div className="d-flex flex-column align-items-start justify-content-center ms-2">
          <FormattedNumber
            value={portfolio?.totalBalance}
            decimals={2}
            subZeros
            className="tx-17"
            suffix="$"
            floor
          />
          <div className="tx-13 tx-muted">
            {getEllipsisTxt(address, 4, '0x')}
          </div>
        </div>
      </div>

      {navGroups.map(({title, items}, index) => (
        <React.Fragment key={`profile-nav-group-${index}-${items.length}`}>
          {!!title && (
            <div className="tx-17 tx-semibold mt-3 mb-2">
              {title}
            </div>
          )}
          <div className="card p-0 mb-4 overflow-hidden">
            {items.map((item, index) => (
              <SideNavItem
                key={`profile-nav-item-${index}-${item.title}`}
                {...item}
                isLast={index === group.length - 1}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
});

export default Profile;
