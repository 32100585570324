import {MONTH_NAMES} from "../constants";

export const n6 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
});
export const n4 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
});

export const c2 = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @param {string} remove string to remove from str
 * @returns {string}
 */
export const getEllipsisTxt = (str, n = 6, remove = '') => {
  if (str) {
    return `${remove}${str.replace(remove, '').slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

export const tokenValue = (value, decimals) =>
  decimals ? value / Math.pow(10, decimals) : value;

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */
export const tokenValueTxt = (value, decimals, symbol) =>
  `${n4.format(tokenValue(value, decimals))} ${symbol}`;

const twoDigits = (value) => value > 9 ? value : `0${value}`

export function msToString(ms) {
  const hours = Math.floor(ms / 1000 / 60 / 60);
  const minutes = Math.floor(ms / 1000 / 60 % 60);
  const seconds = Math.floor(ms / 1000 % 60);

  return `${hours}h ${minutes}m ${String(seconds).padStart(2, '0')}s`;
}

export function toDateTime(dateString) {
  const date = new Date(dateString)
  return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`
}

export function yesNo(value) {
  return value ? "Yes" : "No"
}

export function diffPercents(percents) {
  const sign = percents > 0 ? '+' : ''
  return `${sign}${percents.toFixed(2)}%`
}
