import IdentIcon from "@components/common/IndetIcon";
import {useState} from "react";
import {chainLogo} from "@helpers/chains";

interface TokenLogoProps {
  address: string;
  logo?: string | null;
  name: string;
  size?: number;
  className?: string;
  containerClassName?: string;
  chain?: string;
}

export default function TokenLogo(props: TokenLogoProps) {
  const {logo, address, name, size = 50, containerClassName = '', className = '', chain} = props;
  const isLogoExist = logo && (logo.includes('.png') || logo.includes('.jpg') || logo.includes(address));
  const [isError, setIsError] = useState(false);

  return (
    <div className={`pos-relative ${containerClassName}`}>
      <div className={`ms-0 wd-${size} mn-wd-${size} mx-wd-${size} ht-${size} rounded-50 overflow-hidden token-logo d-flex ${className}`}>
        {isLogoExist && !isError
          ? <img
            src={logo}
            alt={name}
            className="rounded-50"
            onError={(currentTarget) => {
              console.log('onError', currentTarget);
              // @ts-ignore
              currentTarget.onerror = null;
              setIsError(true);
            }}
          />
          : <IdentIcon string={address} size={size} />
        }
      </div>
      {chain && (
        <div className="chain-label">
          <img src={chainLogo(chain)} alt={chain} className="wd-20 ht-20" />
        </div>
      )}
    </div>
  );
}
